import React from "react"
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"

class Footer extends React.Component {
render() {
  return (
    <div className = "bodyElement" style = {{height:`350px`, fontFamily:`receipt, Sans-Serif`, fontSize:`small`, textAlign:`center`}}>
    <a href = "/"><StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "goose.png" }) {
              childImageSharp {
                fluid(maxWidth:200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <Img style = {{maxWidth:`100px`, margin:`auto`, marginBottom:`10px`}}fluid = {data.file.childImageSharp.fluid}/>
        )}
      /></a>
    <div><a href = "/"><b>SILLY GOOSE RECEIPTS</b></a></div>
    <div><i>CREATED WITH LOVE <br/> IN BROOKLYN, NYC</i><br/>-<a href='https://brettbejcek.com'>BRETT BEJCEK</a> </div>
    <div style = {{fontSize:`x-small`}}><br/>(LEGAL STUFF <a href='/terms' target="_blank">HERE</a> AND <a href='/privacy-policy' target="_blank">HERE</a>)</div>
    </div>
)
}
}

export default Footer;
