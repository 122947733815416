import React from "react"
import '../css/global.css'
import { StaticQuery, graphql } from "gatsby"
import Layout from './../components/layout.js'
import '../css/index.css'
import SubmitIdea from './../components/submitidea.js'
import Footer from './../components/footer2.js'
import { Helmet } from "react-helmet"

class Index extends React.Component {
render() {
  return (
    <Layout>
    <StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => (
    <Helmet title="Silly Goose Receipts: Submit your idea">
    <meta name="description" content="Have an idea for a challenge to get people enjoying the little novel moments of life? You've come to the right place."/>
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Silly Goose Receipts: Submit your idea" />
    <meta property="og:description" content="Have an idea for a challenge to get people enjoying the little novel moments of life? You've come to the right place." />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="Silly Goose Receipts: Submit your idea" />
    <meta name="twitter:description" content="Have an idea for a challenge to get people enjoying the little novel moments of life? You've come to the right place." />
    <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
    <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
    <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
    </Helmet>
  )}
/>
    <div className="bodyContainer" style = {{textAlign:`left`, overflow:`hidden`, display:`inline-block`, position:`relative`, marginBottom:`4rem`}}>
    <br/>
    <h1 className="bodyElement" style = {{fontFamily:`receipt, Sans-Serif`}}><a style = {{color:`black`, textDecoration:`none`}} href = "/">Silly Goose Receipts</a></h1>
    <SubmitIdea/>
    </div>
    <Footer/>
    </Layout>
)
}
}

export default Index;
